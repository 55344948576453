@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap);
@import url(https://db.onlinewebfonts.com/c/aa03af4dce48676d5cef178a88beb68f?family=Storytella);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  background: #f3f3f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --active: #faca3a;
  --primary: #198754;
  --primary-light: #59c6bc;
}

h1 {
  font-weight: 600;
  margin: 0px;
}
h2 {
  font-weight: 600;
  margin: 0px;
}
h5 {
  font-size: 16px;
  font-weight: 400;
  color: #777;
  margin: 0px;
}
.h5_font_family {
  color: #000;
  font-size: 16px;
  margin: 0px;
  font-family: "Jost", sans-serif;
  padding-bottom: 3px;
}

.flex-grow-1{
  flex-grow: 1;
}

.input {
  width: 100%;
  padding: 12px;
  border-radius: 13px;
  border: 1px solid #f8bd0c;
  background: #ffffff;
  font-weight: 600;
  font-size: 13px;
  color: #1c1d1f;
  outline: none;
  resize: none;
  overflow: hidden;
}

.input:focus{
  color: #1c1d1f;
  box-shadow: 0px 3px 8px rgba(0,0,0,0.5);
}

.input::placeholder{
  color: rgb(108, 111, 118);
}

button[type="primary"] {
  cursor: pointer;
  width: 100%;
  border-radius: 25px;
  color: #000000;
  background-color: #f8bd0c;
  padding: 13px 22px;
  border: 2px solid #f8bd0c;
  font-weight: 700;
  transition: all 0.3s linear;
  font-size: .9rem;
}

button[type="primary"]:hover {
  transform: scale(1.03);
  background-color: #ffffff;
  border: 2px solid #f8bd0c;
}

button[type="secondry"] {
  width: 100%;
  border-radius: 25px;
  color: #000000;
  background-color: #ffffff;
  padding: 13px 22px;
  border: 2px solid #f8bd0c;
  font-weight: 700;
  transition: all 0.3s linear;
  cursor: pointer;
  font-size: .9rem;
}

button[type="secondry"]:hover {
  transform: scale(1.03);
  background-color: #f8bd0c;
  border: 2px solid #f8bd0c;
}

button[type="disabled"] {
  color: #fff;
  font-size: 15px;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  border: 0px;
  border-radius: 50px;
  background-color: #C7C7C7;
}

.spacing_sm {
  margin-top: 12px;
  margin-bottom: 12px;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.bg-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(/static/media/bg-01.10201a0e.webp);
  background-size: 100% 100%;
}
.register .bg-container {
  padding-bottom: 100px;
  background-size: 100% auto;
}
.ai-center {
  align-items: center;
}
.jc-center {
  justify-content: center;
}

.flex-grow-1{
  flex-grow: 1;
}

.mainCard {
  display: flex;
  flex-direction: row;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 10px #474747;
  background-color: #fff;
  margin: 1rem;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
}
.card-plan {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
}
.elevated {
  box-shadow: 0px 0px 30px #0000000d;
}
.elevated_deep {
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
}
.clickable {
  cursor: pointer;
}

@keyframes color-up {
  to {
    color: #000;
    background-color: var(--active);
  }
}
.m-12 {
  margin: 12px;
}
.m-24 {
  margin: 24px;
}
.p-12 {
  padding: 12px;
}
.p-24 {
  padding: 24px;
}
/* css for Links */
a {
  text-decoration: none;
  color: #777;
}
/* css for slider*/
.slick-prev::before,
.slick-next::before {
  display: none;
}
.zoom-in {
  transition: all 0.3s;
}
.card:hover .zoom-in,
.card:active .zoom-in,
.card-plan:hover .zoom-in,
.card-plan:active .zoom-in,
.card-plan:focus .zoom-in,
.card:focus .zoom-in {
  scale: 1.1;
}
.bg-black {
  background: #000;
}
.dashboard-heading {
  text-align: center;
  font-size: 35px;
  padding: 40px 0 10px 0;
  color: #2d8b82;
}
.title_font {
  font-family: "Jost", sans-serif;
  position: relative;
}
.title_underline {
  margin-bottom: 30px;
}
.title_underline::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.title_underline::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #2d8b82;
  bottom: 0;
  left: calc(50% - 20px);
}
/* css for mui input */
.MuiFilledInput-root {
  width: 100%;
  background-color: transparent !important;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin: 5px 0;
}
.MuiFilledInput-root::before {
  border-bottom: none !important;
}
.tab_container {
  width: 100%;
  display: flex;
  border-radius: 50;
  overflow: hidden;
  text-align: center;
  margin: auto;
  border-radius: 25px;
  line-height: 25px;
}
.schedule .tab_container {
  line-height: 26px;
  width: 500px;
}
.register select {
  -webkit-appearance: none;
}

.legal-page .container {
  margin: 0 50px;
  border-radius: 10px;
  text-align: justify;
  line-height: 30px;
}
.google_login_button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  background: rgb(220, 40, 40);
  color: #fff;
}
.google_login_button img {
  padding: 0 5px;
  margin-right: 5px;
  filter: invert(1);
  border-right: 2px solid #000;
}
.google_login_button:hover {
  background: rgb(182, 30, 30);
}

@media screen and (max-width: 480px){
  .mainCard .imgWrap{
    display: none;
  }
}

@media screen and (orientation: portrait) {
  .container{
    height: 100vh;
  }
}

@media screen and (orientation: landscape) {
  .container{
    height: 100vh;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dashboard_dashboard__2z50d {
  margin-left: 250px;
  background: #f3f3f9;
}
.dashboard_toggle_button__3-ahX img {
  position: absolute;
  right: 20px;
}
/* dashboard navbar */
.dashboard_dashboard_navbar__1vX1c {
  margin: 0;
  margin-left: 250px;
  text-align: -webkit-right;
  align-items: center;
  background: #fff;
  padding: 5px 20px;
  position: relative;
  box-shadow: 1px 5px 4px #ddd;
}
.dashboard_dashboard_navbar__1vX1c .dashboard_dropdown_button__1EE_3 {
  position: relative;
  width: 150px;
  border: 2px solid #ddd;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
  cursor: pointer;
  background: rgb(241, 241, 241);
}
.dashboard_dashboard_navbar__1vX1c .dashboard_dropdown_button__1EE_3 > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
}
.dashboard_dashboard_navbar__1vX1c .dashboard_dropdown_button__1EE_3 > div:first-child > div {
  overflow-x: auto;
}
.dashboard_dashboard_navbar__1vX1c .dashboard_dropdown_button__1EE_3 .dashboard_dropdown__39kSy {
  position: absolute;
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  background: white;
  width: 150px;
  margin-top: 15px;
  transition: all 1s;
  right: 0;
  background: rgb(247, 247, 247);
  z-index: 1;
}
.dashboard_dashboard_navbar__1vX1c .dashboard_dropdown_button__1EE_3 .dashboard_dropdown__39kSy div {
  position: relative;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 2px solid #ddd;
}
.dashboard_dashboard_navbar__1vX1c .dashboard_dropdown_button__1EE_3 .dashboard_dropdown__39kSy div:hover {
  border-bottom: 2px solid black;
}
/* class curriculum */
.dashboard_lang_button__3fySS a {
  position: absolute;
  top: -54px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background: #198754;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  border: 2px solid #198754;
}
.dashboard_lang_button__3fySS:hover a {
  opacity: 1;
}
.dashboard_lang_button__3fySS:hover a:hover {
  background: transparent;
  color: #198754;
}
.dashboard_contact_button__2NfgQ {
  text-align: center;
  width: 250px;
  margin: auto;
  background: #fff;
  padding: 14px 0;
  border: 2px solid #2d8b82;
  border-radius: 5px;
  font-size: 15px;
  color: #2d8b82;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
}
.dashboard_contact_button__2NfgQ:hover {
  color: #fff;
  background: #2d8b82;
}
/* register page */
.dashboard_register_button__li3Dl {
  width: 100%;
  border-radius: 25px;
  color: #000000;
  background-color: #fff;
  padding: 13px 22px;
  border: 2px solid #f8bd0c;
  font-weight: 700;
  transition: all 0.3s linear;
  cursor: pointer;
}
.dashboard_register_button__li3Dl:hover {
  transform: scale(1.03);
  background-color: #f8bd0c;
  border: 2px solid #f8bd0c;
}

.text-stroke {
  text-shadow: 2px 0px #EE2935;
}

.navbarAccordion_accordion__1LdUT {
  width: 250px;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  background: #fff;
  box-shadow: 1px 1px 10px 4px #ddd;
}
.navbarAccordion_accordionHeader__2Nq3K {
  display: flex;
  padding: 30px;
}
.navbarAccordion_accordionHeader__2Nq3K > div {
  padding-top: 12px;
  margin: 0 10px;
}
.navbarAccordion_profileTittle__1UgSC {
  color: #198754;
  font-weight: 700;
}
.navbarAccordion_profileName__3HTAb {
  font-weight: 600;
  font-size: 14px;
}
.navbarAccordion_profileImg__2APvA {
  border-radius: 50%;
}
.navbarAccordion_accordionBody__K-pIb ul {
  list-style: none;
  padding: 0;
}
.navbarAccordion_accordionBody__K-pIb ul li {
  color: #000;
  padding: 10px 40px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
}
.navbarAccordion_accordionBody__K-pIb ul li:hover,
.navbarAccordion_accordionBody__K-pIb ul li:active,
.navbarAccordion_accordionBody__K-pIb ul li:focus {
  background: rgb(244, 244, 244);
  color: #198754;
  border: none;
  transition: all 0.3s;
}
.navbarAccordion_accordionBody__K-pIb .navbarAccordion_activeLink__1MbES li {
  background: rgb(244, 244, 244);
  color: #198754;
  transition: all 0.3s;
}
.navbarAccordion_accordionBottom__3VTdG {
  margin-top: 100px;
}
.navbarAccordion_accordionBottom__3VTdG ul {
  justify-content: space-around;
  list-style: none;
  display: flex;
  padding: 0 55px;
}
.navbarAccordion_accordionBottom__3VTdG ul li {
  cursor: pointer;
  margin: 0 6px;
}
.navbarAccordion_activeNavbar__3LkcH {
  position: absolute;
  left: -1000px;
}
.navbarAccordion_terms_list__30i08 {
  list-style: none;
  font-size: 12px;
}
.navbarAccordion_terms_list__30i08 li {
  padding: 5px 0;
  -webkit-text-decoration: underline rgb(76, 76, 76);
          text-decoration: underline rgb(76, 76, 76);
  transition: all 0.2s;
  cursor: pointer;
}
.navbarAccordion_terms_list__30i08 li:hover {
  scale: 1.02;
  color: rgb(45, 139, 130);
  -webkit-text-decoration-color: rgb(45, 139, 130);
          text-decoration-color: rgb(45, 139, 130);
}

@keyframes revertBorder {
  0% {
    border-color: #fff5d3;
  }
  100% {
    border-color: #efc940;
  }
}

.listWrap li {
  padding: 1.5rem 0rem;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
  text-transform: uppercase;
  font-style: normal;
  border-top: 3px solid #efc94000;
  transition: 0.6s all ease;
}

.listWrap a {
  color: #282a43 !important;
}

.listWrap li:hover {
  border-top: 3px solid #efc940;
}

.btnDonate {
  border: 1.5rem solid #efc940;
  transition: all 0.3s ease-in-out;
}

.btnDonate:hover {
  border: 1.5rem solid #fff5d3;
  animation: revertBorder 0.3s 0.3s forwards;
}

.newsInput,
.newsInput:autofill {
  width: 100%;
  background: #ffffff00;
  color: #ffffff;
  outline: none;
  resize: none;
  overflow: hidden;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  border: 2px solid var(--Grey-Heading, #25252500);
  border-bottom: 2px solid var(--Grey-Heading, #4d4b84);
  margin-bottom: 1.8rem;
  padding: 4px 0px;
}

.footerTextHover {
  cursor: pointer;
  border-bottom: 2px solid #efc94000;
  transition: all 0.2s linear;
}

.footerTextHover:hover {
  border-bottom: 2px solid #efc940;
}

@media (max-width: 900px) {
  .listWrap {
    visibility: hidden;
    width: 0 !important ;
  }

  .headerNse {
    visibility: hidden;
    height: 0;
    width: 0 !important ;
  }
}

.slider-container {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 3rem 0;
}

.slider {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  height: 28px;
  border-radius: 70px;
  cursor: pointer;
  border: 1px solid #000000;
  overflow: hidden;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .slider::-webkit-slider-runnable-track {
    height: 28px;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    color: #13bba4;
  }

  .slider::-webkit-slider-thumb {
    height: 28px;
    width: 28px;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    background: #efc94000;
  }
}

.slider-tooltip {
  position: absolute;
  top: -35px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-tooltip-text {
  background-color: #f4c542;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f4c542;
  margin-top: -3px;
}

.slider-values {
  position: absolute;
  left: 0;
  right: 0;
  top: 53%;
}

.slider-mark {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -17%);
}

.dot {
  height: 1.1rem;
  width: 1.1rem;
  background-color: #00a651;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.dot.active {
  opacity: 1;
}

.mark-label {
  width: 1rem;
  font-weight: 600;
  margin-top: 0.5rem;
  display: block;
  font-size: 12px;
  color: #333;
}

.menu {
  box-sizing: border-box;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
}

.menu li {
  width: 50%;
  padding-right: 10px;
  display: list-item;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
  box-sizing: border-box;
  margin-bottom: 3px;
}

.menu li a {
  font-weight: 500;
  color: #979797;
}

.menu li a:after {
  content: "";
  display: block;
  height: 2px;
  margin-top: 2px;
  transition: width 0.3s;
}

.menu li a:hover::after {
  width: 100%;
  background: #efc940;
}

.helpo_donation {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.helpo_button {
  line-height: 1.7;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  padding: 14px 40px;
  border-radius: 50px;
  min-width: 160px;
  letter-spacing: 0.05em;
  transition: all 0.3s;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  margin-top: 4px;
  transform: translateY(-3px);
  box-shadow: none;
  background: #efc940;
  color: #333333;
  background-color: #e9ba13;
}

.fontQuicksand {
  font-family: Quicksand;
}

.fontStorytella {
  font-family: Storytella;
}

a {
  text-decoration: none;
  font-style: normal;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
}

.m-0 {
  margin: 0;
}

.m-5 {
  margin: 3rem;
}

.ms-4 {
  margin-left: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.my-5 {
  margin: 3rem 0rem;
}

.my-4 {
  margin: 1.5rem 0rem;
}

.mx-3 {
  margin: 0rem 1rem;
}

.mx-5 {
  margin: 0rem 3rem;
}

.py-5 {
  padding: 3rem 0rem;
}

.py-2 {
  padding: 0.75rem 0rem;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.d-inline {
  display: inline;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.gap-4 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.gap-5 {
  grid-gap: 3rem;
  gap: 3rem;
}

.list-unstyled {
  list-style: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.containerD {
  width: 100%;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .containerD {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .containerD {
    max-width: 960px;
  }
}

@media (min-width: 992px) {
  .containerD {
    max-width: 960px;
  }
}

.containerF {
  width: 100%;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .containerF {
    max-width: 600px;
  }
}

@media (min-width: 768px) {
  .containerF {
    max-width: 1080px;
  }
}

@media (min-width: 992px) {
  .containerF {
    max-width: 1080px;
  }
}

