@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@import url("https://db.onlinewebfonts.com/c/aa03af4dce48676d5cef178a88beb68f?family=Storytella");

.fontQuicksand {
  font-family: Quicksand;
}

.fontStorytella {
  font-family: Storytella;
}

a {
  text-decoration: none;
  font-style: normal;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
}

.m-0 {
  margin: 0;
}

.m-5 {
  margin: 3rem;
}

.ms-4 {
  margin-left: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.my-5 {
  margin: 3rem 0rem;
}

.my-4 {
  margin: 1.5rem 0rem;
}

.mx-3 {
  margin: 0rem 1rem;
}

.mx-5 {
  margin: 0rem 3rem;
}

.py-5 {
  padding: 3rem 0rem;
}

.py-2 {
  padding: 0.75rem 0rem;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.d-inline {
  display: inline;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 3rem;
}

.list-unstyled {
  list-style: none;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.containerD {
  width: 100%;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .containerD {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .containerD {
    max-width: 960px;
  }
}

@media (min-width: 992px) {
  .containerD {
    max-width: 960px;
  }
}

.containerF {
  width: 100%;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .containerF {
    max-width: 600px;
  }
}

@media (min-width: 768px) {
  .containerF {
    max-width: 1080px;
  }
}

@media (min-width: 992px) {
  .containerF {
    max-width: 1080px;
  }
}
